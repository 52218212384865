/* You can add global styles to this file, and also import other style files */
@import "~angular-notifier/styles";
@import "~tippy.js/dist/tippy.css";

:root {
    --amplify-primary-color: #2e58a5;
    --amplify-primary-tint: #307aea;
    --amplify-primary-shade: rgb(95, 123, 171);

}

@media (max-width: 767.98px) {
    .fc .fc-toolbar.fc-header-toolbar {
        display: block;
        text-align: center;
    }

    .fc-header-toolbar .fc-toolbar-chunk {
        display: block;
    }
}


@import '~@syncfusion/ej2-base/styles/material.css';  
@import '~@syncfusion/ej2-buttons/styles/material.css';  
@import '~@syncfusion/ej2-calendars/styles/material.css';  
@import '~@syncfusion/ej2-dropdowns/styles/material.css';  
@import '~@syncfusion/ej2-inputs/styles/material.css';  
@import '~@syncfusion/ej2-navigations/styles/material.css';
@import '~@syncfusion/ej2-popups/styles/material.css';
@import '~@syncfusion/ej2-splitbuttons/styles/material.css';
@import '~@syncfusion/ej2-notifications/styles/material.css';
@import '~@syncfusion/ej2-angular-grids/styles/material.css';

@import '~quill/dist/quill.bubble.css'; 
// or
@import '~quill/dist/quill.snow.css';

